// 引入请求api
import { fun_GetEnterInfo } from "@/api";
// 引入时间转换方法
import { formatDate } from "@/utils/validate";
// 默认导出
export default {
  data: () => {
    return {
      form: {
        name: null,
        keyword: null
      },
      formSearchList: [
        {
          type: "input",
          label: "企业名称：",
          value: "name",
          placeholder: "请输入企业名称"
        },
        {
          type: "input",
          label: "社会信用代码：",
          value: "keyword",
          placeholder: "请输入社会信用代码"
        }
      ],
      data: [
        { label: "企业名称", value: "", key: "name" },
        { label: "注册资本", value: "", key: "regCapital" },
        { label: "统一社会信用代码", value: "", key: "creditCode" },
        { label: "组织机构代码", value: "", key: "orgNumber" },
        { label: "工商注册号", value: "", key: "regNumber" },
        { label: "经营状态", value: "", key: "regStatus" },
        { label: "法定代表人", value: "", key: "legalPersonName" },
        { label: "公司类型", value: "", key: "companyOrgType" },
        { label: "成立日期", value: "", key: "estiblishTime" },
        { label: "营业期限", value: "", key: "businessTerm" },
        { label: "所属行业", value: "", key: "industry" },
        { label: "核准日期", value: "", key: "approvedTime" },
        { label: "实缴资本", value: "", key: "actualCapital" },
        { label: "人员规模", value: "", key: "staffNumRange" },
        {
          label: "注册地址",
          value: "",
          key: "regLocation"
        },
        {
          label: "经营范围",
          value: "",
          key: "businessScope"
        }
      ]
    };
  },
  mounted() {
    this.fun_GetList();
    // 清空sessionStorage
    sessionStorage.clear();
    console.log(new Date(""));
  },
  methods: {
    /**
     * @description 查询企业信息
     * @author 滕威
     */
    async fun_GetList() {
      if (!this.form.name) {
        this.$delete(this.form, "name");
      }
      let res = await fun_GetEnterInfo(
        "/services/open/ic/baseinfo/2.0",
        this.form
      );
      const { result, error_code } = res.data;
      if (error_code === 0) {
        for (const key in result) {
          for (const i of this.data) {
            if (i.key === key) {
              i.value = result[key];
            }
            switch (i.key) {
              case "estiblishTime":
                i.value = i.value
                  ? formatDate(new Date(i.value), "yyyy-MM-dd hh:mm:ss")
                  : "";
                break;
              case "approvedTime":
                i.value = i.value
                  ? formatDate(new Date(i.value), "yyyy-MM-dd hh:mm:ss")
                  : "";
                break;
            }
            // 营业期限
            if (i.key === "businessTerm") {
              i.value = `${
                result["fromTime"]
                  ? formatDate(
                      new Date(result["fromTime"]),
                      "yyyy-MM-dd hh:mm:ss"
                    )
                  : ""
              } 至 ${
                result["toTime"]
                  ? formatDate(
                      new Date(result["toTime"]),
                      "yyyy-MM-dd hh:mm:ss"
                    )
                  : ""
              }`;
            }
          }
        }
      }
    },
    /**
     * @description 查询按钮
     * @author 滕威
     */
    fun_Search() {
      this.fun_GetList();
    }
  }
};
